/* BANNER IMAGE  */
.productpagecontainer {
  background: var(--bg);
}
.p-info-container {
  padding: 50px;
  padding-top: 180px;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  background-position: top;
  background-size: cover;
  align-items: flex-end;
  width: 100%;
  background: var(--bg);
  position: relative;
}
.p-info-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.pro-img {
  z-index: 9999;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.pro-img img,
.pro-img .imagess {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 15px;
  margin-right: 15px;
  position: relative;
  z-index: 0;
}
.pro-img .imagess {
  height: 100px;
  background-image: linear-gradient(
    to left,
    transparent,
    rgba(255, 255, 255, 0.8),
    transparent
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  z-index: 2;
}
@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.pro-img h2 {
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
  z-index: 9999;
}
.pro-img h6 {
  color: #fff;
  z-index: 9999;
}
.pro-img .features {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.pro-img .features button {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px;
  border-radius: 10px;
  color: #000;
  outline: transparent;
  margin-right: 10px;
  border: transparent;
  .icon {
    margin-right: 5px;
  }
}
.instruction-image {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  padding-top: 20px;
  z-index: 99999;
}
.instruction-image button {
  background-color: var(--s);
  padding: 5px 10px;
  border-radius: 10px;
  color: #000;
  outline: transparent;
  border: transparent;
  font-size: 15px;
}
.ins-popup {
  display: none;
}
.ins-popup.active {
  position: relative;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 400px;
  height: 400px;
  padding: 20px;
}
.ins-popup .close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  color: red;
  border: 2px solid #fff;
  border-radius: 100px;
  font-size: 40px !important;
  cursor: pointer;
}

.ins-popup img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
}

.helpimg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999999;
  gap: 10px;
}
.helpimg .icon {
  font-size: 50px;
  color: #fff;
  cursor: pointer;
}
.helpimg.active {
  scale: 1;
}
.helpimg img {
  border: 2px solid #fff;
  width: 30%;
}

/* Notification  */
.notification-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 999999;
  color: #000;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}
.notification-modal .notification {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  width: 30%;
}
.notification-modal .notification h4 {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}
.notification-modal .notification p {
  font-size: 14px;
}
.notification-modal .notification button {
  padding: 10px 20px;
  background-color: var(--s);
  color: #000;
  width: 100%;
  border-radius: 15px;
  outline: transparent;
  border: transparent;
}

.product-info-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  background-color: var(--p);
  color: #333;
}

.product-info-img {
  width: 100%;
  height: 350px;
}
.product-info-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-info-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.product-info-content select {
  padding: 10px;
  margin: 10px 0;
  margin-bottom: 0;
  width: 50%;
}
.player-tag {
  padding: 10px;
  width: 50%;
  outline: transparent;
  border: 1px solid grey;
  margin-top: 10px;
  color: #000;
}
.product-info-content h3 {
  text-transform: uppercase;
}
.add-to-cart-btn {
  width: 100%;
  color: #000;
  padding: 10px;
  outline: transparent;
  border: transparent;
  margin-top: 10px;
  background: var(--btn);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}
.gotocart {
  background: green;
  color: #fff;
}
.clearcartbtn {
  background: tomato;
  color: #fff;
}
.safe-checkout-img {
  width: 35%;
  margin-top: 10px;
}
.product-desc-delivery-details {
  padding: 50px 20px;
}
.tab-btns-desc {
  display: flex;
}
.tab-btns-desc .tab {
  padding: 10px;
  text-align: center;
  width: 50%;
  background-color: #eee;
  cursor: pointer;
}
.tab-btns-desc .tab.active {
  background-color: var(--s);
}
.product-info-details {
  margin-top: 20px;
  min-height: 250px;
}
.amount-and-fields {
  width: 75%;
  display: block;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 50px;
  gap: 20px;
}
.p-amount {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.info-user-fields {
  width: 100%;
  position: relative;
  .icon {
    cursor: pointer;
  }
}
.amount {
  width: 18%;
  border-radius: 10px;
  color: #fff;
  background-color: #22282a;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 10px;
}
.amount .up {
  display: flex;
  padding: 15px 8px;
  border-radius: 10px;
  position: relative;
  /* background: linear-gradient(to right, #ffca00, #ffe26e); */
  background-color: #fff;
  background-size: 200% 200%;
  animation: smoothBackgroundChange 6s ease infinite;
  align-items: center;
  border: 3px solid #000;
}
.amount.active {
  .up {
    background-color: #ffca00;
    background-size: 200% 200%;
    animation: smoothBackgroundChange 6s ease infinite;
  }
}

@keyframes smoothBackgroundChange {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.amount .up .image img {
  width: 50px;
}
.amount .up .pack p {
  margin: 0;
  color: #000;
}
.amount .up .pack p:nth-child(1) {
  font-weight: 600;
  font-size: 14px;
}
.amount .up .pack p:nth-child(2) {
  font-size: 12px;
}
.amount .down {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.amount .down p {
  margin: 0;
}
.amount .down p:nth-child(2) {
  margin: 0;
  text-decoration: line-through !important;
  color: #737474;
  font-size: 12px;
}
/* END  */

/* ====================== PAYMENT  */
/* ====================== PAYMENT  */
/* ====================== PAYMENT  */

.bgpadding {
  padding: 20px;
  border-radius: 15px;
  background-color: #eee;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.payment {
  color: #000;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}
.payment .wallet.active,
.payment .upi.active {
  background-color: var(--s);
  color: #000;
}
.payment h4 {
  font-size: 20px;
}
.wallet,
.upi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #fff;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  min-height: 70px;
}
.wallet .icon {
  font-size: 30px;
}
.payment .pimages {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.payment .pimages img {
  width: 40px;
  border-radius: 10px;
}
/* payment  */
/* payment  */
/* payment  */

.p-check-btn {
  outline: transparent;
  border: transparent;
  background-color: var(--s);
  color: #000;
  font-weight: 500;
  padding: 10px 20px;
  margin-left: 5px;
}

.product-desc {
  padding: 50px 200px;
  background-color: var(--t);
  color: #333;
}
.product-desc p {
  white-space: pre-wrap;
}
.product-desc-delivery-details {
  padding: 50px 20px;
}
.user-input-fields {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.user-input-fields .player-tag {
  width: 100%;
}
.user-input-fields button {
  width: 100%;
}
.totalamount-paymentmode {
  display: flex;
  justify-content: space-between;
}
.safe-checkout {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0 0 20px 20px;
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  span {
    background-color: #000;
    color: #fff;
    padding: 5px;
  }
}
.safe-checkout-img {
  width: 100%;
}

.order-success-container {
  width: 50%;
  display: block;
  margin: 0 auto;
  height: 80vh;
  padding-top: 50px;
}
.order-success-container .icon {
  color: green;
  text-align: center;
  font-size: 70px;
  margin-bottom: 10px;
  animation: scale 2s infinite ease;
}
@keyframes scale {
  0%,
  100% {
    transform: scale(100%);
  }
  50% {
    transform: scale(80%);
  }
}
.order-success-container h4 {
  background-color: #333;
  color: #fff;
  padding: 5px;
  margin: 0;
}
.order-success-container .btnss {
  display: flex;
  gap: 10px;
}
.order-success-container .btnss button {
  width: 100%;
  outline: transparent;
  border: transparent;
  padding: 5px;
  background-color: var(--s);
  color: #000;
  border-radius: 2px;
}

@media screen and (max-width: 992px) {
  .product-info-container {
    /* padding: 50px 20px; */
  }
  .product-info-img-slider {
    width: 100%;
    flex-direction: row;
    gap: 10px;
  }
  .product-info-img-slider img {
    width: 20%;
    margin-right: 10px;
  }
  .product-info-img {
    width: 100%;
  }
  .product-info-content {
    width: 100%;
    padding-left: 0;
  }
  .select-brand select {
    width: 100%;
  }
  .add-to-cart-btn {
    width: 100%;
  }
  .safe-checkout-img {
    width: 100%;
  }
  .product-info-content select,
  .player-tag {
    width: 100%;
  }
  .product-desc {
    padding: 50px 20px;
  }

  /* BANNER  */
  .p-info-container {
    padding: 10px;
    padding-top: 100px;
  }
  .pro-img h2 {
    font-size: 15px;
    margin: 0;
  }
  .pro-img img {
    width: 80px;
    height: 80px;
  }
  .pro-img .features button {
    font-size: 8px;
    padding: 2px;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 5px;
    .icon {
      font-size: 10px;
    }
  }
  .ins-popup img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 786px) {
  .amount-and-fields {
    width: 100%;
    flex-wrap: wrap;
    padding: 10px;
  }
  .p-amount {
    width: 100%;
    margin-top: 20px;
  }
  .amount {
    width: 48%;
  }
  .info-user-fields {
    width: 100%;
  }
  .notification-modal .notification {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    width: 100%;
  }
  .instruction-image {
    padding: 20px;
    padding-bottom: 0;
    .helpimg img {
      width: 90%;
    }
  }
  .instruction-image button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    gap: 3px;
  }
  .instruction-image .icon {
    font-size: 18px;
  }
  .ins-popup.active {
    width: 75%;
    height: auto;
  }
  .bgpadding {
    padding: 0;
    background-color: transparent;
    border: transparent;
  }

  .order-success-container {
    width: 100%;
    height: 80vh;
  }
}
