.admin-layout-container {
  background-color: #111;
  color: #fff;
}
/* ADMIN HEADER  */
.admin-header-main {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  background-color: #111;
  color: #fff;
  position: relative;
}
.admin-tools {
  display: flex;
  gap: 10px;
}
/* ADMIN HEADER ENDS  */
/* ADMIN BODY  */
.admin-body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;
  min-height: calc(100vh - 100px);
}
.admin-sidebar {
  width: 15%;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.admin-body-content {
  width: 84%;
}
.admin-footer {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

/* MOBILE MENU FOR ADMIN */
/* ADMIN SIDEMENU CSS  */
.admin-sidemenu-container {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  z-index: 9999;
  padding: 20px;
  width: 100%;
  background-color: #fff;
  color: #000;
  transition: 0.3s all ease;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.admin-sidemenu-container li {
  margin: 20px;
}
.admin-sidemenu-container a {
  font-size: 22px;
  color: #333;
  font-weight: 600;
}
.admin-sidemenu-container .cancel-icon {
  font-size: 50px;
}
.admin-sidemenu-container.active {
  left: 0;
}

@media screen and (max-width: 992px) {
  .admin-header-main {
    padding: 0 10px;
  }
  .admin-body-content {
    width: 100%;
  }
}
