.sticky-footer {
  position: fixed;
  left: 50%;
  bottom: 10px;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  transform: translateX(-50%);
  justify-content: space-between;
  border-radius: 5px;
  padding: 8px 10px;
  gap: 10px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 999;
}
.sticky-footer .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sticky-footer .item .icon {
  margin-bottom: 4px;
}
.sticky-footer .item .icon.active {
  color: var(--s);
}
.sticky-footer .item span {
  font-size: 13px;
}
.sticky-footer .item span.active {
  color: var(--s);
}
