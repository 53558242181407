.query-reply-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.back-btnn {
  cursor: pointer;
}
.query-msg {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  align-self: flex-start;
  width: 60%;
}
.query-msg.active {
  align-self: flex-end;
  background-color: #ffca00;
}
.ticketclosed {
  background-color: tomato;
  width: 100%;
  color: #fff;
  padding: 5px;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 15px;
}
