.register-container {
  padding: 100px 20px;
  background-color: var(--p);
  color: #333;
}
.form {
  padding: 20px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}
.form-fields input,
.form-fields select,
.form-fields textarea {
  box-shadow: none !important;
  border-radius: 0;
  padding: 15px 10px;
}
.form-fields label {
  color: #333;
}
.register-form h1 {
  font-weight: 400;
}
.register-btn {
  width: 100%;
  background-color: #ffca00;
  padding: 15px;
  color: #000;
  font-weight: 500;
  &:hover {
    opacity: 0.9;
  }
}
.apply-code-btn {
  padding: 8px 15px;
  width: 180px;
  background-color: #222f3e;
  color: #fff;
  outline: transparent;
  border: transparent;
  &:hover {
    opacity: 0.8;
  }
}

@media screen and (max-width: 992px) {
  .forgot-pass h6 {
    font-size: 12px;
  }
}
