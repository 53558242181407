.phone-skins-products-container {
  padding: 50px;
  display: flex;
  justify-content: space-between !important;
  border: 1px solid red;
  width: 100%;
}
.phone-skins-container img {
  width: 100%;
}
