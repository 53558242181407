.followus-container {
  padding: 50px 200px;
  text-align: center;
  background-color: var(--s);
  color: #000;
}
.followus-container h2 {
  font-weight: 700;
}
.followus-container span {
  text-transform: uppercase;
}
.followus-container .socials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.followus-container .socials img {
  width: 70px;
  padding: 10px;
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
  scale: 1;
  transition: 0.5s all ease;
}
.followus-container .socials img:hover {
  scale: 1.1;
}
@media screen and (max-width: 786px) {
  .followus-container {
    padding: 30px 20px;
  }
  .followus-container .socials {
    gap: 10px;
  }
  .followus-container .socials img {
    width: 50px;
  }
}
