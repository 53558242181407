.wallet-dash {
  display: flex;
  padding: 30px 0px;
  padding-top: 0;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}
.wallet-dash-card {
  width: 30%;
  padding: 20px;
  background-color: #ffca00;
  cursor: pointer;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
.wallet-dash-card.active {
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.add-money {
  display: flex;
  width: 100%;
  gap: 50px;
}
.bar-img {
  width: 50%;
}
.txn-details {
  width: 50%;
}
.bar-img img {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .wallet-dash-card {
    width: 100%;
  }
  .add-money {
    padding: 50px 10px;
    flex-wrap: wrap;
  }
  .bar-img {
    width: 100%;
  }
  .txn-details {
    width: 100%;
  }
}
