@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --p: #fff;
  --s: #ffac00;
  --t: #f7f7f7;
  --btn: linear-gradient(to right, #ffca00, #ffe26e);
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
  list-style: none !important;
  text-decoration: none !important;
}
ul {
  margin: 0 !important;
  padding: 0 !important;
}
.popp {
  font-family: "Poppins", sans-serif !important;
}
.caveat {
  font-family: "Caveat", cursive;
  font-weight: 500;
}

@media screen and (min-width: 993px) {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: linear-gradient(#333, #000);
  }
}
