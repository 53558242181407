.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* &:hover { */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* } */
}
.popup-container img {
  width: 35%;
  display: block;
  margin: 0 auto;
  border-radius: 20px;
  transition: 0.5s all ease;
  &:hover {
    width: 36%;
  }
}
.popup-container .icon {
  font-size: 50px;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 786px) {
  .popup-container {
    img {
      width: 95%;
    }
  }
}
