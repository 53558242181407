.no-order-found {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: #eee;
  padding: 20px;
}
.view-btn {
  padding: 2px 10px;
  outline: transparent;
  border: transparent;
  background-color: var(--s);
  color: #fff;
}
.user-order-container .ant-pagination-item a {
  color: #fff !important;
}
.user-order-container .ant-pagination-item-active a {
  color: var(--s) !important;
}
.user-order-container .tools {
  margin-bottom: 20px;
  overflow-x: scroll;
}
.user-order-container .tools button {
  max-width: fit-content;
  font-size: 12px;
}
.user-order-container .tools input {
  width: 100% !important;
}
.order-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.order-container .order {
  width: 30%;
  background-color: var(--t);
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #000;
  padding: 10px;
  border-radius: 10px;
}
.order-container .order .order-date {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #333;
  margin-bottom: 10px;
}
.order-container .order .order-date button {
  font-size: 10px;
  padding: 0 5px;
}
.order-container .order .order-img-content {
  display: flex;
  gap: 10px;
}
.orderimg {
  width: 25%;
}
.ordercontent {
  width: 75%;
}
.orderimg img {
  width: 100%;
  border-radius: 10px;
}
.orderimg .gameicon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  .icon {
    font-size: 40px;
    color: #f8e623;
  }
}
.ordercontent p {
  margin: 0;
  font-size: 12px;
}
.ordercontent p:nth-child(2) {
  font-size: 12px;
}

/* // PAGINATION  */

.user-order-container .ant-pagination {
  background-color: var(--t);
  color: #000;
  border-radius: 5px;
  padding: 20px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.user-order-container .ant-pagination-item {
  color: #000;
}

.user-order-container .ant-pagination-item-active {
  color: #000;
}

@media screen and (max-width: 992px) {
  .order-container .order {
    width: 100%;
  }
}
